.gm-style-iw + div {display: none;}

.map-popover__title {
  margin-top: 0;
  margin-bottom: 8px;
  text-align:center;
}

.map-popover-details__icon {
  margin-right: 6px;
}

.map-popover-details__icon,
.map-popover-details__icon + td {
  vertical-align: middle;
}

.rc-slider-tooltip {
  z-index: 4000;
  margin:0;
}

@-webkit-keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.is-loading {
  color: transparent!important;
  pointer-events: none;
  position: relative;
  overflow: hidden;
}

.is-loading::before {
  z-index: 100;
  background: rgba(0, 0, 0, 0.4);
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.is-loading:after {
  z-index: 101;
  -webkit-animation: spinAround .5s linear infinite;
  animation: spinAround .5s linear infinite;
  border-radius: 290486px;
  border-color: transparent transparent #dbdbdb #dbdbdb;
  border-style: solid;
  border-width: 2px;
  content: "";
  display: block;
  height: 1em;
  width: 1em;
  position: absolute !important;
  left: calc(50% - .5em);
  top: calc(50% - .5em);
  opacity: 1!important;
}

.tracks-tooltip svg{
  font-size: 26px;
}

.map-parcel-names {
  margin-left: 0 !important;
  margin-top: 0 !important;
  height: 20px !important;
  width: auto !important;
}

.location-tooltip {
  position: relative;
  background: #ffffff;
  border: 1px solid #000000;
  width: 180px;
  padding: 8px;
  border-radius: 4px;
  opacity: 0.7;
  display: flex;
  flex-direction:column;
  align-items: flex-start;
}
.location-tooltip-title{
  font-weight: 700;
}
.location-tooltip-title{
  white-space: nowrap;
}
.location-tooltip:after, .location-tooltip:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.location-tooltip:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #ffffff;
  border-width: 5px;
  margin-left: -5px;
}
.location-tooltip:before {
  border-color: rgba(0, 0, 0, 0);
  border-top-color: #000000;
  border-width: 6px;
  margin-left: -6px;
}
