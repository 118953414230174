.leaflet-editing-icon {
  width: 6px !important;
  height: 6px !important;
  margin-left: -3px !important;
  margin-top: -3px !important;
}

.leaflet-marker-icon,
.marker-icon,
.leaflet-marker-draggable {
  border-color: #f7931e !important;
  border-width: 2px !important;
}
