.marker-cluster {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: rgba(46,232,234,0.6);
}
.marker-cluster div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    white-space: nowrap;

    font: 11px "Ubuntu", Arial, Helvetica, sans-serif;
    color: white;
    background-color: rgba(29,215,217,0.6);
}
