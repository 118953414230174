.t_block .SingleDatePicker {
  display: block;
  width: 100%;
}

.SingleDatePicker .DateInput {
  width: 100%;
}

.DateRangePickerInput {
  background-color: transparent;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.DateRangePickerInput_calendarIcon {
  font-size: 1.414em;
  padding-top: 0;
  padding-bottom: 0;
  outline: none;
  margin-left: 0;
  color: white;
}

.DateRangePickerInput_calendarIcon svg {
  height: 1em;
  width: 1em;
  fill: currentColor;
}

.DateInput {
  background: transparent;
  padding: 4px;
  width: 5em;
  text-align: center;
}

.DateInput_input,
.DateInput_input__focused {
  font-size: 14px;
  font-weight: 400;
  padding: 0;
  text-align: center;
  border-bottom: 0;
  cursor: pointer;
}

@media screen and (max-width: 1180px) {
  .DateInput_input,
  .DateInput_input__focused {
    font-size: 12px;
  }
  .DateInput {
    width: 3.8em;
  }
  .DateRangePickerInput_calendarIcon {
    font-size: 1em;
  }
  .DayPicker__horizontal > div {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .DayPicker__horizontal > div {
    width: 100%;
  }
  .DayPicker__horizontal > div > div {
    width: 100%;
  }
  .DayPicker__shortcuts {
    margin: 0 0 0 !important;
    padding: 10px !important;
    border-left: none !important;
    border-bottom: 1px solid #dedbd7;
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
  .DayPicker__shortcuts ul {
    display: flex;
  }
}

.DateInput_input__focused {
  color: white;
  background: #478324;
  border-bottom: 0;
  border-radius: 4px;
}

/* colors */

.DateRangePickerInput_arrow {
  font-size: 14px;
}

.DateInput__with_caret::before {
  top: 40px;
}

.DateInput__with_caret::after {
  top: 41px;
}

.DateRangePicker_picker,
.SingleDatePicker_picker {
  border-radius: 4px;
}

.DayPicker__horizontal {
  width: inherit !important;
}
.DayPicker__horizontal > div {
  display: flex;
  flex-direction: row-reverse;
}

.DayPicker__shortcuts {
  order: -1;
  margin: 70px 0 20px 0;
  padding: 0 9px;
  border-left: 1px solid #dedbd7;
  width: 180px;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #478324;
  border: 1px double #478324;
  color: #fff;
}

.CalendarDay__selected_span,
.CalendarDay__hovered_span,
.CalendarDay__after_hovered_start {
  background: #a1d169;
  border: 1px double #7eaf30;
  color: #fff;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span.CalendarDay__hovered {
  background: #478324;
  border: 1px double #478324;
  color: #fff;
}

.CalendarDay__selected_start,
.CalendarDay__selected_end,
.CalendarDay__selected,
.CalendarDay__selected:hover,
.CalendarDay__selected_start:active,
.CalendarDay__selected_end:active,
.CalendarDay__selected_start:hover,
.CalendarDay__selected_end:hover,
.CalendarDay__hovered {
  background: #478324;
  color: #fff;
  border: 1px double #478324;
}

.CalendarMonth__caption {
  margin-bottom: 12px;
}

.DateRangePickerInput_calendarIcon {
  margin: 0;
  padding: 0 8px 0 16px;
  font-size: 1em;
}

.SingleDatePickerInput__withBorder {
  border: 1px solid #CBCBCB;
  border-radius: 4px;
}
