.rc-slider-handle,
.rc-slider-handle:hover,
.rc-slider-track {
  background-color: #f7931e;
  border-color: #f7931e;
}

.rc-slider-rail {
  background-color: #eae9e5;
  border-radius: 999px;
}

.rc-slider-tooltip-arrow {
  display: none;
}

.rc-slider-tooltip-inner {
  background-color: #4c453b;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  padding: 1em;
  height: auto;
  position: relative;
  border-radius: 4px;
  font-size: 14px;
}

.rc-slider-tooltip-inner:after {
  content: '';
  top: 100%;
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-top-color: #4c453b;
  border-width: 6px;
  margin-left: -6px;
}
